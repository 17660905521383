(function($){

	// Initial Instantiations of Select2
	$('#billing_state').select2({
		minimumResultsForSearch: Infinity,
	});

	$('#billing_country').select2({
		minimumResultsForSearch: Infinity,
	});

})(jQuery);
