(function ($) {
    $('a[href^="#"]').click(function() {
        if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
          var $target = $(this.hash);
          $target = $target.length && $target || $('[name=' + this.hash.slice(1) +']');

          if ($target.length) {
            var height = $('.SiteHeader').outerHeight(true);
            console.log(height);
            if($('body').hasClass('admin-bar')) {
                if($(window).width() >= 768) {
                    var targetOffset = $target.offset().top - $('.SiteHeader').outerHeight(true) - 32;
                    console.log(targetOffset);
                }
                else {
                    var targetOffset = $target.offset().top - $('.SiteHeader').outerHeight(true);
                    console.log(targetOffset);
                }
            }
            else {
                var targetOffset = $target.offset().top - $('.SiteHeader').outerHeight(true);
                console.log(targetOffset);
            }
            $('html,body').animate({scrollTop: targetOffset}, 500);
                return false;
            }
        }
    });
}(jQuery));
