(function ($) {

		$('.Product-slider--init').slick({
				slidesToScroll: 1,
				slidestToShow: 1,
				arrows: true,
				dots: false,
				fade: true,
				lazyLoad: 'progressive',
				cssEase: 'linear',
				prevArrow: $('.Product-slider-prev'),
				nextArrow: $('.Product-slider-next'),
		});

		// We needed a preset min-height to prevent the slide from collapsing on load
		// So after the image loads this will reset back to the typical image height.
		$('.Product-slider--init').on('lazyLoaded', function() {
			$('.Product-slider--slide').removeClass('Product-slider--slide_lazy');	
		});

		$('#Product-slider-box_toggle').on('click', function() {
				$(this).toggleClass('Product-slider-box_toggle--return');
				$('.Product-order_data').toggleClass('Product-order_data--hidden');
		});


		$(document).on("ready", function () {
				// Establish empty Key var that will gain class on loop
				var key;

				// We need to loop through all potential tooltip containers
				$('.uni_cpo_fields_container').each( function() {
						// Grab the ID of each tooltip container
						var ID = '#' + $(this).attr('id');
						// Get the classes associated with each ID and explode them
						var classList = $(this).attr('class').split(/\s+/);
						// Initial state is false unless a match is found
						var found = false;

						// For each item found loop through it
						for (tooltipItem = 0; tooltipItem < classList.length; tooltipItem++) {

								if (found)
										break;

								for (key in tooltipObject) {
										
										if (classList[tooltipItem] == key) {
												// Tooltip description
												var description = tooltipObject[key];

											 // prepare inner & outter html elements
											 var $button = $("<button>?</button>"),
											 $wrapper = $('<div class="Tooltip-wrapper"></div>');

											 // Use attributes on our elements
											 $button.attr('type', 'button');
											 $button.attr('data-toggle', 'tooltip');
											 $button.attr('data-placement', 'top');
											 $button.attr('data-container', 'body');
											 $button.attr('title', description);
											 $wrapper.append($button);

											 // ID targets first founf label and applies the tooltip
											 $(ID + ' .uni_cpo_fields_header').first().prepend($wrapper);
											 found = true;
											 break;
										}
								}
						}
				});

				$('[data-toggle="tooltip"]').tooltip();
		});


}(jQuery));

