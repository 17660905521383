/*global define, console, document, window*/
(function (root, factory) {
    "use strict";
    if (typeof define === 'function' && define.amd) {
        define("AffixColumn", ["jquery", "Behaviors"], factory);
    } else {
        root.AffixColumn = factory(root.jQuery, root.Behaviors);
    }
}(this, function ($, Behaviors) {
    "use strict";

    var module = {};
    
    function $do(that, target) {
        return function () {
            target.apply(that, arguments);
        };
    }
    
    function Affix(elem, scrollElem) {
        Behaviors.init(Affix, this, arguments);

        this.height = this.$elem.height();
        this.offsetTop = this.$elem.offset().top;
        
        this.columns = [];
        this.$scrollElem = $(scrollElem || document);
        
        this.bind_event_handlers();
        this.find_columns();
        
        this.resized();
        this.scroll_changed();
    }
    
    Behaviors.inherit(Affix, Behaviors.Behavior);
    
    Affix.QUERY = "[data-affixcolumn='root']";

    Affix.prototype.resized = function () {
        var i, maxColHeight = 0, maxColId;
        
        this.height = this.$elem.height();
        
        if (this.columns.length > 0) {
            for (i = 0; i < this.columns.length; i += 1) {
                console.log(this.columns[i]);
                if (maxColHeight < this.columns[i].height) {
                    maxColHeight = this.columns[i].height;
                    maxColId = i;
                }
                
                this.columns[i].remove_state("tallest");
            }
            
            this.columns[maxColId].add_state("tallest");
        }
    };
    
    Affix.prototype.scroll_changed = function () {
        var i, maxColHeight = 0, maxColId;
        
        this.height = this.$elem.height();
        this.offsetTop = this.$elem.offset().top;
        this.scrollTop = this.$scrollElem.scrollTop();
        this.offsetBottom = this.offsetTop + this.height;
        this.scrollBottom = this.scrollTop + this.height;
        
        if (this.columns.length > 0) {
            for (i = 0; i < this.columns.length; i += 1) {
                this.columns[i].viewport_changed(this.height, this.offsetTop, this.offsetBottom, this.scrollTop, this.scrollBottom);
            }
        }
    };
    
    Affix.prototype.unbind_event_handlers = function () {
        if (this.scroll_handler !== undefined) {
            this.$scrollElem.off("scroll", this.scroll_handler);
        }
        
        if (this.resize_handler !== undefined) {
            $(window).off("resize", this.resize_handler);
        }
    };
    
    Affix.prototype.bind_event_handlers = function () {
        this.unbind_event_handlers();
        
        this.scroll_handler = $do(this, this.scroll_changed);
        this.resize_handler = $do(this, this.resized);
        
        this.$scrollElem.on("scroll", this.scroll_handler);
        $(window).on("resize", this.resize_handler);
        $(document).on("load", this.resize_handler);
        $("img").on("load", this.resize_handler);
    };
    
    Affix.prototype.find_columns = function () {
        this.columns = AffixColumn.find_markup(this.$elem);
    };
    
    function AffixColumn(elem) {
        this.$elem = $(elem);
        this.height = this.$elem.height();
    }
    
    Behaviors.inherit(AffixColumn, Behaviors.Behavior);
    
    AffixColumn.QUERY = "[data-affixcolumn='column']";
    
    AffixColumn.prototype.add_state = function (state) {
        this.$elem.addClass("is-AffixColumn--" + state);
    };
    
    AffixColumn.prototype.remove_state = function (state) {
        this.$elem.removeClass("is-AffixColumn--" + state);
    };
    
    AffixColumn.prototype.viewport_changed = function (rootHeight, offsetTop, offsetBottom, scrollTop, scrollBottom) {
        this.height = this.$elem.height();
        
        if (scrollTop < offsetTop) {
            this.add_state("top");
            this.remove_state("bottom");
        } else if (scrollTop + this.height >= offsetBottom) {
            this.remove_state("top");
            this.add_state("bottom");
        } else {
            this.remove_state("top");
            this.remove_state("bottom");
        }
    };
    
    $(document).ready(function () {
        console.log("AffixColumn: Loading Affix roots...");
        Affix.find_markup($(document));
    });

    module.Affix = Affix;
    module.AffixColumn = AffixColumn;

    return module;
}));
