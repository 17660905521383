(function($) {

	//apply now nav
	$("document").ready(function(){

		var  mn = $(".applyNow");
	    mns = "main-nav-scrolled";
	    hdr = $('.HeroHeader-careersSingle').outerHeight(true)-75;

		$(window).scroll(function() {
		  if( $(this).scrollTop() > hdr ) {
		    mn.addClass(mns);

		  } else {
		    mn.removeClass(mns);

		  }
		});	
	})

}(jQuery));


