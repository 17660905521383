(function ($) {
    $(document).ready(function() {
        if($('.SplitText').length) {
            if($(window).width() >= 992)  {
                $('.SplitText-container').addClass('animated slow fade');
                new Waypoint.Inview({
				 	element: $('.SplitText-container')[0],
				    enter: function(direction) {
                        if(direction === "down") {
				    	   $('.SplitText-container').addClass('fadeInUpAlt');
                            console.log('entered');
                        }
				    },
				    exited: function(direction) {
				    	if(direction === "up") {
							$('.SplitText-container').removeClass('fadeInUpAlt');
                            console.log('exited');
						}
					}
				});
            }
        }
    });
    $(window).resize(function() {
        if($('.SplitText').length) {
            if($(window).width() >= 992) {
                $('.SplitText-container').addClass('animated slow fade');
                new Waypoint.Inview({
				 	element: $('.SplitText-container')[0],
				    enter: function(direction) {
                        if(direction === "down") {
				    	   $('.SplitText-container').addClass('fadeInUpAlt');
                        }
				    },
				    exited: function(direction) {
				    	if(direction === "up") {
							$('.SplitText-container').removeClass('fadeInUpAlt');
						}
					}
				});
            }
        }
    });

}(jQuery));
