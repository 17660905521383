(function ($) {
    $(document).ready(function() {
        if($('.MosaicCTA--horizontal .MosaicCTA-container--desktop').length) {
            if($(window).width() >= 768)  {
                new Waypoint.Inview({
				 	element: $('.MosaicCTA-container--desktop')[0],
				    enter: function(direction) {
				    	$('.MosaicCTA-image--desktop_1').addClass('slideDownRightFade');
                        $('.MosaicCTA-image--desktop_2').addClass('slideDownLeftFade');
                        $('.MosaicCTA-image--desktop_3').addClass('slideUpRightFade');
                        $('.MosaicCTA-image--desktop_4').addClass('slideUpLeftFade');
				    },
				    exited: function(direction) {
				    	if(direction === "up") {
							$('.MosaicCTA-image--desktop_1').removeClass('slideDownRightFade');
                            $('.MosaicCTA-image--desktop_2').removeClass('slideDownLeftFade');
                            $('.MosaicCTA-image--desktop_3').removeClass('slideUpRightFade');
                            $('.MosaicCTA-image--desktop_4').removeClass('slideUpLeftFade');
						}
					}
				});
            }
        }

        if($('.MosaicCTA--vertical .MosaicCTA-container--desktop').length) {
            if($(window).width() >= 768)  {
                new Waypoint.Inview({
				 	element: $('.MosaicCTA-container--desktop')[0],
				    enter: function(direction) {
				    	$('.MosaicCTA-image--desktop_1').addClass('slideInDownFade');
                        $('.MosaicCTA-image--desktop_2').addClass('slideInUpFade');
                        $('.MosaicCTA-image--desktop_3').addClass('slideInDownFade');
                        $('.MosaicCTA-image--desktop_4').addClass('slideInUpFade');
				    },
				    exited: function(direction) {
				    	if(direction === "up") {
							$('.MosaicCTA-image--desktop_1').removeClass('slideInDownFade');
                            $('.MosaicCTA-image--desktop_2').removeClass('slideInUpFade');
                            $('.MosaicCTA-image--desktop_3').removeClass('slideInDownFade');
                            $('.MosaicCTA-image--desktop_4').removeClass('slideInUpFade');
						}
					}
				});
            }
        }
    });
    $(window).resize(function() {
        if($('.MosaicCTA--horizontal .MosaicCTA-container--desktop').length) {
            if($(window).width() >= 768) {
                new Waypoint.Inview({
				 	element: $('.MosaicCTA-container--desktop')[0],
				    enter: function(direction) {
				    	$('.MosaicCTA-image--desktop_1').addClass('slideDownRightFade');
                        $('.MosaicCTA-image--desktop_2').addClass('slideDownLeftFade');
                        $('.MosaicCTA-image--desktop_3').addClass('slideUpRightFade');
                        $('.MosaicCTA-image--desktop_4').addClass('slideUpLeftFade');
				    },
				    exited: function(direction) {
				    	if(direction === "up") {
							$('.MosaicCTA-image--desktop_1').removeClass('slideDownRightFade');
                            $('.MosaicCTA-image--desktop_2').removeClass('slideDownLeftFade');
                            $('.MosaicCTA-image--desktop_3').removeClass('slideUpRightFade');
                            $('.MosaicCTA-image--desktop_4').removeClass('slideUpLeftFade');
						}
					}
				});
            }
        }
        if($('.MosaicCTA--vertical .MosaicCTA-container--desktop').length) {
            if($(window).width() >= 768)  {
                new Waypoint.Inview({
				 	element: $('.MosaicCTA-container--desktop')[0],
				    enter: function(direction) {
				    	$('.MosaicCTA-image--desktop_1').addClass('slideInDown');
                        $('.MosaicCTA-image--desktop_2').addClass('slideInUp');
                        $('.MosaicCTA-image--desktop_3').addClass('slideInDown');
                        $('.MosaicCTA-image--desktop_4').addClass('slideInUp');
				    },
				    exited: function(direction) {
				    	if(direction === "up") {
							$('.MosaicCTA-image--desktop_1').removeClass('slideInDown');
                            $('.MosaicCTA-image--desktop_2').removeClass('slideInUp');
                            $('.MosaicCTA-image--desktop_3').removeClass('slideInDown');
                            $('.MosaicCTA-image--desktop_4').removeClass('slideInUp');
						}
					}
				});
            }
        }
    });
}(jQuery));
