(function($){

  $(document).ready(function(){

    var link = $(".page404--link-text");
    var top = $(".top");
    var bottom = $(".bottom");

    link.hover(function(){
      top.removeClass("show").addClass("hide");
      bottom.removeClass("hide").addClass("show");

    },function(){
      top.removeClass("hide").addClass("show");
      bottom.removeClass("show").addClass("hide");

    });
  });


})(jQuery);
