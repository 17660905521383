(function ($) {

    $('.EnvironmentSlider-text_slider').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        asNavFor: '.EnvironmentSlider-image_slider',
        dots: true,
        fade: true,
        arrows: false,
        appendDots: '.EnvironmentSlider-text_slider--dots',
       // mobileFirst: true,
    });


    $('.EnvironmentSlider-image_slider').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        asNavFor: '.EnvironmentSlider-text_slider',
        arrows: true,
        dots: false,
        prevArrow: '.EnvironmentSlider-button--left',
        nextArrow: '.EnvironmentSlider-button--right',
        mobileFirst: true,
        responsive: [
        {
          breakpoint: 991,
          settings: {
            centerMode: true,
            centerPadding: '60px',
          }
        },
        {
          breakpoint: 1199,
          settings: {
            centerMode: true,
            centerPadding: '80px',
          }
        }
        // You can unslick at a given breakpoint now by adding:
        // settings: "unslick"
        // instead of a settings object
      ]

    });
}(jQuery));
