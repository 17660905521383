(function ($) {

    $(document).ready(function() {
        if($('.ContentOverlap').length) {
            if($(window).width() >= 768)  {
                $('.ContentOverlap').addClass('animated slow fade');
                new Waypoint.Inview({
				 	element: $('.ContentOverlap')[0],
				    enter: function(direction) {
                        if(direction === "down") {
				    	   $('.ContentOverlap').addClass('fadeInUpAlt');
                        }
				    },
				    exited: function(direction) {
				    	if(direction === "up") {
							$('.ContentOverlap').removeClass('fadeInUpAlt');
						}
					}
				});
            }
        }
    });
    $(window).resize(function() {
        if($('.ContentOverlap').length) {
            if($(window).width() >= 768) {
                $('.ContentOverlap').addClass('animated slow fade');
                new Waypoint.Inview({
				 	element: $('.ContentOverlap')[0],
				    enter: function(direction) {
                        if(direction === "down") {
				    	   $('.ContentOverlap').addClass('fadeInUpAlt');
                        }
				    },
				    exited: function(direction) {
				    	if(direction === "up") {
							$('.ContentOverlap').removeClass('fadeInUpAlt');
						}
					}
				});
            }
        }
    });

}(jQuery));
