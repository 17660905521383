(function ($) {
    function navHover() {
        var original = $('.NavMenu--main_menu-link').width();
        if($(window).width() >= 992) {
            $('.NavMenu--dropdown .NavMenu--main_menu-link').each(function() {
                $(this).hover(function() {
                    $(this).addClass('grow');
                    $(this).siblings().addClass('shrink');
                    console.log('hover is working');
                }, function(){
                    $(this).removeClass('grow');
                    $(this).siblings().removeClass('shrink');
                });
            });
        }
    }

    navHover();
    $(window).resize(function() {
        navHover();
    });
}(jQuery));
