(function ($) {

    $('.HeroHeader-link').hover(function() {
        $('.HeroHeader-subtitle--home.transparent').removeClass('show');
        $('.HeroHeader-subtitle--home.transparent').addClass('hide');
        $('.HeroHeader-subtitle--home.opaque').addClass('show');
        $('.HeroHeader-subtitle--home.opaque').removeClass('hide');
        $('.HeroHeader-background').addClass('show');
        $('.HeroHeader-title--home, .HeroHeader-section_anchor').addClass('change-color');
    }, function(){
        $('.HeroHeader-background').removeClass('show');
        $('.HeroHeader-title--home, .HeroHeader-section_anchor').removeClass('change-color');
        $('.HeroHeader-subtitle--home.transparent').addClass('show');
        $('.HeroHeader-subtitle--home.transparent').removeClass('hide');
        $('.HeroHeader-subtitle--home.opaque').removeClass('show');
        $('.HeroHeader-subtitle--home.opaque').addClass('hide');
    });

}(jQuery));


(function ($) {
    $(document).ready(function() {
        if($('.HeroHeader-images--desktop').length) {
            if($(window).innerWidth() >= 768)  {
                new Waypoint.Inview({
                    element: $('.HeroHeader-images--desktop')[0],
                    enter: function(direction) {
                        $('.HeroHeader-image--1').addClass('changeOpacityLeft-right-1');
                        $('.HeroHeader-image--2').addClass('changeOpacityLeft-right-2');
                        $('.HeroHeader-image--3').addClass('changeOpacityLeft-right-3');
                        $('.HeroHeader-image--4').addClass('changeOpacityLeft-right-4');
                        $('.HeroHeader-mosaic-title--wrapper').addClass('changeOpacityLeft-right-5');
                    },
                    exited: function(direction) {
                        if(direction === "up") {
                            $('.HeroHeader-image--1').removeClass('changeOpacityLeft-right-1');
                            $('.HeroHeader-image--2').removeClass('changeOpacityLeft-right-2');
                            $('.HeroHeader-image--3').removeClass('changeOpacityLeft-right-3');
                            $('.HeroHeader-image--4').removeClass('changeOpacityLeft-right-4');
                            $('.HeroHeader-mosaic-title--wrapper').removeClass('changeOpacityLeft-right-5');
                        }
                    }
                });
            }
        }
    });
    $(window).resize(function() {
        if($('.HeroHeader-images--desktop').length) {
            if($(window).innerWidth() >= 768) {
                new Waypoint.Inview({
                    element: $('.HeroHeader-images--desktop')[0],
                    enter: function(direction) {
                        $('.HeroHeader-image--1').addClass('changeOpacityLeft-right-1');
                        $('.HeroHeader-image--2').addClass('changeOpacityLeft-right-2');
                        $('.HeroHeader-image--3').addClass('changeOpacityLeft-right-3');
                        $('.HeroHeader-image--4').addClass('changeOpacityLeft-right-4');
                    },
                    exited: function(direction) {
                        if(direction === "up") {
                            $('.HeroHeader-image--1').removeClass('changeOpacityLeft-right-1');
                            $('.HeroHeader-image--2').removeClass('changeOpacityLeft-right-2');
                            $('.HeroHeader-image--3').removeClass('changeOpacityLeft-right-3');
                            $('.HeroHeader-image--4').removeClass('changeOpacityLeft-right-4');
                        }
                    }
                });
            }
        }
    });
}(jQuery));
