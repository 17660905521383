(function ($, VideoPlayer) {
    //This code loads the IFrame Player API code asynchronously.
 //    var tag = document.createElement('script');
	// tag.src = "https://www.youtube.com/iframe_api";
	// var firstScriptTag = document.getElementsByTagName('script')[0];
	// firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);


    $('.VideoEmbed-video iframe').addClass('VideoEmbed-video--iframe');
    // var source = $('.VideoEmbed-video--iframe').attr('src');
    // var sourceNew = source + "&showinfo=0&modestbranding=1&html5=1&vq=hd720";
    // $('.VideoEmbed-video--iframe').attr('src', sourceNew);

    $('.VideoSlider-video_slider').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        asNavFor: '.VideoSlider-nav_slider',
        arrows: true,
        dots: true,
        dotsClass: 'VideoSlider-video_slider--dots slick-dots',
        prevArrow: '<button type="button" class="VideoSlider-video_slider--button VideoSlider-video_slider--left slick-prev">Previous</button>',
        nextArrow: '<button type="button" class="VideoSlider-video_slider--button VideoSlider-video_slider--right slick-next">Next</button>',
        mobileFirst: true,
        responsive: [
            {
                breakpoint: 767,
                settings: {
                    dots: false
                }
            }
        ]
    });

    $('.VideoSlider-nav_slider').slick({
        slidesToShow: 5,
        slidesToScroll: 1,
        swipeToSlide: true,
        asNavFor: '.VideoSlider-video_slider',
        dots: false,
        arrows: false,
        focusOnSelect: true,
    });

    $('.VideoSlider-video_slider--slide iframe').each(function(index) {
        // var id = 'vidslide' + index;
        // $(this).addClass('VideoSlider-video_slider--iframe');
        // $(this).attr('id', id);
        // var idEach = '#' + id;
        // var srcEach = $(this).attr('src');
        // var srcEachNew = srcEach + "&enablejsapi=1&showinfo=0&modestbranding=1&html5=1&vq=hd720";
        // $(this).attr('src', srcEachNew);
    });

    // $('.VideoSlider-video_slider').on('beforeChange', function(event, slick, currentSlide, nextSlide) {
    //     console.log('current slide is ' + currentSlide);
    //     $(this).find("[data-videoplayer='youtube']").each(function (index, youtubeElem) {
    //         var youtubeControl = VideoPlayer.VideoPlayer__youtube.locate(youtubeElem);
    //         console.log('youtube control is ' + youtubeControl);
    //         youtubeControl.ready().then(function () {
    //             if(!youtubeControl.is_paused()) {
    //                 youtubeControl.pause();
    //                 console.log('paused');
    //             }
    //         });
    //     });
    // });



    /*
     * The below example uses Slick Carousel, however this can be
     * extended into any type of carousel, provided it lets you
     * bind events when the slide changes. This will only work
     * if all framed videos have the JS API parameters enabled.
     */
    
    //bind our event here, it gets the current slide and pauses the video before each slide changes.
    $(".VideoSlider-video_slider").on("beforeChange", function(event, slick) {

      var currentSlide, slideType, player, command;
      
      //find the current slide element and decide which player API we need to use.
      currentSlide = $(slick.$slider).find(".slick-current");
      
      //determine which type of slide this, via a class on the slide container. This reads the second class, you could change this to get a data attribute or something similar if you don't want to use classes.
      slideType = currentSlide.data("videoplayer");
      
      //get the iframe inside this slide.
      player = currentSlide.find("iframe").get(0);
      
      if (slideType == "vimeo") {
        command = {
          "method": "pause",
          "value": "true"
        };
      } else {
        command = {
          "event": "command",
          "func": "pauseVideo"
        };
      }
      
      //check if the player exists.
      if (player != undefined) {
        //post our command to the iframe.
        player.contentWindow.postMessage(JSON.stringify(command), "*");
      }
    });

}(jQuery, VideoPlayer));
