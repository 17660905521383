(function ($) {

    $('.Project-slider--init').slick({
        slidesToScroll: 1,
        slidestToShow: 1,
        arrows: true,
        dots: false,
        fade: true,
        cssEase: 'linear',
        adaptiveHeight: 'true',
        prevArrow: $('.Project-slider-prev'),
        nextArrow: $('.Project-slider-next'),
    });


}(jQuery));
